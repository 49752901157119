<template>
    <b-sidebar
            id="add-new-user-sidebar"
            :visible="isActive"
            bg-variant="white"
            sidebar-class="sidebar-lg"
            shadow
            backdrop
            no-header
            right
            @change="(val) => $emit('update:is-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    افزودن مدیر جدید
                </h5>

                <feather-icon
                        class="ml-1 cursor-pointer"
                        icon="XIcon"
                        size="16"
                        @click="hide"
                />

            </div>

            <!-- Form -->
            <b-form class="p-2" @submit.prevent="Submit" @reset.prevent="resetValue">
                <b-form-group label="نام کاربری(ایمیل)">
                    <b-form-input
                            dir="ltr"
                            class="text-left"
                            v-model="form.email"
                            type="email"
                            trim
                            placeholder="نام کاربری"
                            required
                    />
                </b-form-group>
                <b-form-group label="رمز عبور">
                    <b-form-input :state="form.password.length>0" dir="ltr" class="text-left mb-1" type="password"
                                  v-model="form.password" trim placeholder="رمز عبور" required/>
                    <b-form-input :state="form.password===password" dir="ltr" class="text-left" type="password"
                                  v-model="password" trim placeholder="تکرا رمز عبور" required/>
                    <b-form-invalid-feedback>
                        جهت تغییر رمز مدیر
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="form.password!==password">
                        تکرار رمز اشتباه
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="اطلاعات شخصی">
                    <b-form-input dir="ltr" class="text-left mb-1" v-model="form.name" trim
                                  placeholder="نام" required/>
                    <b-form-input dir="ltr" class="text-left mb-1" v-model="form.lastname" trim
                                  placeholder="نام خوانوادگی" required/>
                    <b-form-input dir="ltr" class="text-left" v-model="form.mobile" trim
                                  placeholder="تلفن همراه" required/>
                </b-form-group>
                <b-form-group label="نقش">
                    <v-select
                            v-model="form.role"
                            dir="rtl"
                            :key="roles.length"
                            :options="roles"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="role"
                            style="min-width:105px"
                    />
                </b-form-group>

                <!-- footer -->
                <div class="d-flex mt-2">
                    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled="notFilledAll && (password===form.password)"
                    >
                        ثبت مدیر
                    </b-button>
                    <b-button
                            v-ripple.400="'rgba(199,186,186,0.15)'"
                            type="reset"
                            variant="outline-secondary"
                            class="mr-2"
                    >
                        از نو
                    </b-button>
                    <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-danger"
                            @click="hide"
                    >
                        بازگشت
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
</template>

<script>

    import {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        // BInputGroupPrepend,
        // BInputGroup,
        // BInputGroupAppend,
        // BDropdown,
        // BDropdownItem,

    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        name: "addNewAdmin",
        components: {
            BSidebar,
            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton,
            vSelect,
        },
        props: {
            isActive: {
                type: Boolean,
                required: true,
            },
            roles: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    name: '',
                    lastname: '',
                    email: '',
                    password: '',
                    mobile: '',
                    role: 1
                },
                password: '',
            }
        },
        computed: {
            notFilledAll(){
                for(let a in this.form){
                    if(!this.form[a])
                        return true
                }
                return  false
            }
        },
        methods: {
            resetValue() {
                this.form = {
                    name: '',
                    lastname: '',
                    email: '',
                    password: '',
                    mobile: '',
                    role: 1,
                }
                this.password = ''
            },
            async Submit() {

                this.state.loading = true
                await this.$axios.post('/admins', this.form)

                this.$swal({
                    icon: 'success',
                    title: 'مدیر اضافه شد',
                    confirmButtonText: 'تایید',
                    // text: 'Your file has been deleted.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

                this.form = {
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    mobileNumber: '',
                    role: '',
                }

                this.password = ''
                this.$emit('added')
                this.isActive = false
            }
        }
    }
</script>

<style scoped>

</style>
